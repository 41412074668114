<script>
export default {
  name: 'Icon',
  props: {
    name: {
      type: String,
      default: null
    },
    wrapper: {
      type: Boolean,
      default: false
    },
    size: {
      type: String,
      default: null
    }
  },
  data () {
    return {
      classes: ''
    }
  },
  methods: {
    triggerClickEvent () {
      this.$emit('click')
    },
    getName: function () {
      return 'icon-' + this.name
    },
    getLink: function () {
      return `#icon-${this.name}`
    },
    getSizeClass: function () {
      return this.size === 'large' ? 'is-large' : this.size === 'medium' ? 'is-medium' : this.size === 'small' ? 'is-small' : ''
    }
  }
}
</script>

<template>
  <span
    v-if="wrapper"
    class="icon-wrapper"
    :class="[getSizeClass()]"
  >
    <svg
      class="icon"
      :class="[getName(), classes]"
      xmlns="http://www.w3.org/2000/svg"
      @click="triggerClickEvent"
    >
      <use :xlink:href="getLink()" />
    </svg>
  </span>
  <svg
    v-else
    class="icon"
    :class="[getName(), classes]"
    xmlns="http://www.w3.org/2000/svg"
    @click="triggerClickEvent"
  >
    <use :xlink:href="getLink()" />
  </svg>
</template>

<style src="@/assets/styles/themes/default/icon.css"></style>
